import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import Pagination from "../../Config/Pagination";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import "../../Config/search.css";
import AddContact from "./AddContact";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";
import "../../Config/loader.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Config/loading.css";
import { useLabelContext } from "../../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCity, faHome, faHouseUser, faIndustry, faMap, faMapMarkedAlt, faSeedling, faTractor, faTree } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { formatDistanceToNow } from 'date-fns';
import res from "../../../Assets/residential.png";
import ind from "../../../Assets/industrial.png";
import house from "../../../Assets/house.png";
import building from "../../../Assets/building.png";
import agri from "../../../Assets/Agri.jpg";
import com from "../../../Assets/office-building.png";
import plot from "../../../Assets/plot.jpg";

export default function Leads() {
  const navigate = useNavigate();

  const [leadsData, setLeadsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [contactLeadOBJ, setContactLeadOBJ] = useState([]);
  const [remidnerLeadOBJ, setRemidnerLeadOBJ] = useState([]);
  const [followupLeadOBJ, setFollowupLeadOBJ] = useState([]);
  const { setID } = useLabelContext();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}leads/GetLeads`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setLeadsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setLeadsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Format as dd-mm-yyyy
    return `${day}-${month}-${year}`;
  };

  const showContactModal = (obj) => {
    setContactLeadOBJ(obj);
  };

  const showReminderModal = (obj) => {
    setRemidnerLeadOBJ(obj);
  };

  const showFollowupModal = (obj) => {
    setFollowupLeadOBJ(obj);
  };

  const itemsPerPage = 10;

  const filteredItems = useMemo(() => {
    const results = leadsData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.source_name &&
          item.source_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );


    setCurrentPage(1);

    return results;
  }, [leadsData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExcelDownload = () => {
    if (leadsData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = leadsData.map((item) => ({
      Name: item.name,
      "Property type": item.company,
      "Area": item.city,
      "Budget": item.zip,
      "mobile number": item.phonenumber,
      "Date Created": formatDate(item.datecreated),
      "Last Contact": formatDate(item.lastcontact),
      Source: item.SourceName,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "LeadsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "LeadsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Leads Data",
      author: "Your Name",
      creator: "Your Application",
    });


    const tableData = [];


    const headers = [
      "Name",
      "Property type",
      "Area",
      "Budget",
      "Phone Number",
      "Source",
    ];
    tableData.push(headers);

    // Add rows
    leadsData.forEach((item) => {
      const row = [];
      row.push(
        item.name,
        item.company,
        item.city,
        item.zip,
        item.phonenumber,
        item.SourceName
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("LeadsData.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.id);
  };
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + 'Cr';
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'L';
    if (num >= 1_000) return (num / 1_000).toFixed(1) + 'K';
    return num;
  };

  const toggleActiveStatus = async (id, currentStatus) => {
    try {
      const response = await fetch(`${API_BASE_CRM_URL}leads/ActiveLeads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, active: currentStatus ? 0 : 1 }),
      });

      const result = await response.json();

      if (result.Status) {

        setLeadsData((prevLeads) =>
          prevLeads.map((leads) =>
            leads.id === id
              ? { ...leads, active: currentStatus ? 0 : 1 }
              : leads
          )
        );
        Swal.fire("Success", "Leads status updated successfully", "success");
      } else {
        Swal.fire("Error", "Failed to update Leads status", "error");
      }
    } catch (error) {
      console.error("Error updating Leads status:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating Leads status",
        "error"
      );
    }
  };

  const formatDates = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  const totalLeads = leadsData.length;
  const activeLeads = leadsData.filter(lead => lead.active === 1).length;
  const inactiveLeads = totalLeads - activeLeads;

  const activeResidentialLeads = leadsData.filter(
    lead => lead.pname === "Residential" && lead.active === 1
  ).length;

  const activeCommercialLeads = leadsData.filter(
    lead => lead.pname === "Commercial" && lead.active === 1
  ).length;

  const activeIndustrialLeads = leadsData.filter(
    lead => lead.pname === "Industrial" && lead.active === 1
  ).length;

  const activeAgriculturalLeads = leadsData.filter(
    lead => lead.pname === "Agricultural" && lead.active === 1
  ).length;

  const activeIndependentHouseLeads = leadsData.filter(
    lead => lead.pname === "Independent House" && lead.active === 1
  ).length;

  const activePlotLeads = leadsData.filter(
    lead => lead.pname === "Plot" && lead.active === 1
  ).length;

  const activeApartmentLeads = leadsData.filter(
    lead => lead.pname === "Apartment" && lead.active === 1
  ).length;

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Leads</h4>
            <h6>Manage your leads</h6>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
          <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "50px", padding: "5px 10px" }}>
            <div className="card-body d-flex justify-content-start align-items-center p-0">
              <i className="fa-solid fa-users fa-lg text-primary me-2"></i>
              <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", marginTop: '5px' }}>Total</h5>
              <p className="card-text fs-6 mb-0" style={{ fontSize: "14px" }}>{totalLeads}</p>
            </div>
          </div>
          <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "50px", padding: "5px 10px" }}>
            <div className="card-body d-flex justify-content-start align-items-center p-0">
              <i className="fa-solid fa-user-check fa-lg text-success me-2"></i>
              <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", marginTop: '5px' }}>Active</h5>
              <p className="card-text fs-6 mb-0" style={{ fontSize: "14px" }}>{activeLeads}</p>
            </div>
          </div>
          <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "50px", padding: "5px 10px" }}>
            <div className="card-body d-flex justify-content-start align-items-center p-0">
              <i className="fa-solid fa-user-slash fa-lg text-danger me-2"></i>
              <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", marginTop: '5px' }}>Inactive</h5>
              <p className="card-text fs-6 mb-0" style={{ fontSize: "14px" }}>{inactiveLeads}</p>
            </div>
          </div>
        </div>



        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" style={{ width: "24px", height: "24px" }} />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" style={{ width: "24px", height: "24px" }} />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-lead">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="d-flex flex-wrap mt-0">
        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#f8d7da", borderColor: "#f5c6cb" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faHome} style={{ marginRight: "5px", fontSize: "16px", color: "#dc3545" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "#dc3545" }}>Residential</h5>
            <span className="card-text fs-6 mb-0" style={{ fontSize: "14px", color: "#dc3545" }}>{activeResidentialLeads}</span>
          </div>
        </div>
        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#d4edda", borderColor: "#c3e6cb" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "5px", fontSize: "16px", color: "green" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "green" }}>Commercial</h5>
            <p className="card-text fs-6 mb-0" style={{ fontSize: "14px", color: "green" }}>{activeCommercialLeads}</p>
          </div>
        </div>

        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#d1ecf1", borderColor: "#bee5eb" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faIndustry} style={{ marginRight: "5px", fontSize: "16px", color: "#17a2b8" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "#17a2b8" }}>Industrial</h5>
            <p className="card-text fs-6 mb-0" style={{ fontSize: "14px", color: "#17a2b8" }}>{activeIndustrialLeads}</p>
          </div>
        </div>

        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#fff3cd", borderColor: "#ffeeba" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faSeedling} style={{ marginRight: "5px", fontSize: "16px", color: "#ffc107" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "#ffc107" }}>Agricultural</h5>
            <p className="card-text fs-6 mb-0" style={{ fontSize: "14px", color: "#ffc107" }}>{activeAgriculturalLeads}</p>
          </div>
        </div>

        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#e2e3e5", borderColor: "#d6d8db" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faTree} style={{ marginRight: "5px", fontSize: "16px", color: "#28a745" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "#28a745" }}>Independent House</h5>
            <p className="card-text fs-6 mb-0" style={{ fontSize: "14px", color: "#28a745" }}>{activeIndependentHouseLeads}</p>
          </div>
        </div>

        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#f8d7da", borderColor: "#f5c6cb" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: "5px", fontSize: "16px", color: "#dc3545" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "#dc3545" }}>Plot</h5>
            <p className="card-text fs-6 mb-0" style={{ fontSize: "14px", color: "#dc3545" }}>{activePlotLeads}</p>
          </div>
        </div>

        <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "30px", padding: "5px 10px", backgroundColor: "#d1ecf1", borderColor: "#bee5eb" }}>
          <div className="card-body d-flex justify-content-start align-items-center p-0">
            <FontAwesomeIcon icon={faHouseUser} style={{ marginRight: "5px", fontSize: "16px", color: "#17a2b8" }} />
            <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", color: "#17a2b8" }}>Apartment</h5>
            <p className="card-text fs-7 mb-0" style={{ fontSize: "14px", color: "#17a2b8" }}>{activeApartmentLeads}</p>
          </div>
        </div>
      </div>

      <div className="card table-list-card shadow-sm">
        <div className="card-body bg-light">
          {/* For all screen sizes */}
          <div className="table-responsive" style={{ minHeight: "24rem" }}>
            <table className="table table-hover align-middle">
              <thead className=" text-white text-bold">
                <tr>
                  <th>#</th>
                  <th>Action</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Property Type</th>
                  <th>Preferred Area</th>
                  <th>Budget</th>
                  <th>Assignee</th>
                  <th>Stage</th>
                  <th>Status</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="containers m-auto">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="dropdown">
                          <a
                            className="text-dark"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v"></i>
                          </a>
                          <div className="dropdown-menu shadow-sm">
                            <Link
                              to={`/view-lead`}
                              className="dropdown-item "
                              onClick={() => handleViewPurchaseOrders(item)}


                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={`/edit-lead`}
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-pen-to-square me-2"></i>Edit
                            </Link>
                            <a
                              className="dropdown-item "
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasContact"
                              aria-controls="offcanvasContact"
                              onClick={() => showContactModal(item)}
                            >
                              <i className="fa-regular fa-address-book me-2"></i>
                              Add Contact
                            </a>
                            <a
                              className="dropdown-item "
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasReminder"
                              aria-controls="offcanvasReminder"
                              onClick={() => showReminderModal(item)}
                            >
                              <i className="fa-solid fa-business-time me-2"></i>
                              Set Reminder
                            </a>
                            <a
                              className="dropdown-item "
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasFollowup"
                              aria-controls="offcanvasFollowup"
                              onClick={() => showFollowupModal(item)}
                            >
                              <i className="fa-solid fa-reply-all me-2"></i>
                              Followup
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => toggleActiveStatus(item.id, item.active)}
                            >
                              <i className={`fa-solid fa-toggle-${item.active ? "off text-danger" : "on text-success"} me-2`}></i>
                              {item.active ? "In Active" : "Active"}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link
                          to={`/view-lead`}
                          className="text-decoration-none text-dark fw-bold"
                        >
                          {item.name}
                        </Link>
                      </td>
                      <td>{item.phonenumber}</td>
                      <td className="text-info d-flex justify-content-center align-items-center">
                        <img
                          src={
                            item.pname === "Residential"
                              ? res
                              : item.pname === "Commercial"
                                ? com
                                : item.pname === "Industrial"
                                  ? ind
                                  : item.pname === "Agricultural"
                                    ? agri
                                    : item.pname === "Independent House"
                                      ? house
                                      : item.pname === "Plot"
                                      ? plot
                                      : item.pname === "Apartment"
                                        ? building
                                        : null
                          }
                          alt={item.pname}
                          title={item.pname}
                          style={{ width: "26px", height: "26px" }}
                          className={`me-1 ${item.pname === "Residential"
                            ? "text-success"
                            : item.pname === "Commercial"
                              ? "text-primary"
                              : item.pname === "Industrial"
                                ? "text-warning"
                                : item.pname === "Agricultural"
                                  ? "text-success"
                                  : item.pname === "Independent House"
                                    ? "text-info"
                                    : item.pname === "Apartment"
                                      ? "text-danger"
                                      : ""
                            }`}
                        />
                      </td>



                      <td>{item.city}</td>
                      <td className="text-success fw-bold">{formatNumber(item.zip)}</td>
                      <td>{item.staff_names}</td>
                      <td
                        className={`text-muted ${item.StatusName === "Started" ? "text-success" :
                          item.StatusName === "not started" ? "text-danger" :
                            item.StatusName === "conversation" ? "text-info" :
                              item.StatusName === "loss" ? "text-warning" : "text-muted"}`}
                      >
                        {item.StatusName}
                      </td>
                      <td>
                        <i
                          className={`fa ${item.active === 1 ? "fa-toggle-on text-success" : "fa-toggle-off text-danger"}`}
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </td>
                      <td className="text-info">{formatDates(item.dateadded)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={leadsData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>



      <AddContact leadOBJ={contactLeadOBJ} />
      <SetReminder leadOBJ={remidnerLeadOBJ} />
      <FollowUp leadOBJ={followupLeadOBJ} />
    </Base>
  );
}
