import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";
import '../../Config/swal.css';
import { Select } from "antd";


export default function SetReminder({ taskOBJ }) {

    const [userDetails, setUserDetails] = useState([]);
    const [selectedStaffId, setSelectedStaffId] = useState(null);
    const [staffData, setStaffData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [remindTime, setRemindTime] = useState('');

    const initialFormData = {
        relId: taskOBJ?.id || "",
        reminderType: "Task",
        staffId: selectedStaffId,
        hours: 0,
        days: 0,
        months: 0,
        description: ""
    };

    console.log(initialFormData);

    const [formData, setFormData] = useState(initialFormData);

    console.log(formData);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        setUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            staffId: selectedStaffId
        }));
    }, [selectedStaffId]);

    useEffect(() => {
        if (taskOBJ) {
            setFormData((prevData) => ({
                ...prevData,
                relId: taskOBJ.id || prevData.relId
            }));
        }
    }, [taskOBJ]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
                if (!response.ok) throw new Error("Network response was not ok");
                const data = await response.json();
                console.log(data);

                if (data.Status && Array.isArray(data.ResultData)) {
                    setStaffData(data.ResultData);
                } else {
                    console.error("Invalid staff data:", data);
                }
            } catch (error) {
                console.error("Error fetching staff data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handelStaffChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectedStaffId(selectedOption);

    };

    console.log(selectedStaffId);

    const staffOptions = staffData.map((item) => ({
        value: item.staffid,
        label: `${item.firstname} ${item.lastname}`
    }));

    const handleSubmit = async () => {
        if (!remindTime) {
            alert('Please select remind time.');
            return;
        }
        if (!selectedStaffId) {
            alert('Please select a staff member.');
            return;
        }
        console.log(formData, 'data sending to API');
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_CRM_URL}leads/AddTaskReminder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            const contentType = response.headers.get("Content-Type");
            const result = contentType?.includes("application/json")
                ? await response.json()
                : await response.text();

            console.log(result, 'response received');

            if (result.Status) {
                Swal.fire({
                    title: `Reminder Set Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: result.message || "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Swal.fire({
                title: "Error",
                text: "An unexpected error occurred.",
                icon: "error",
                customClass: {
                    title: 'swal-title',
                    content: 'swal-text'
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (value) => {
        setSelectedCheckbox((prevValue) => (prevValue === value ? "" : value));
    };

    const handleChange1 = (event) => {
        const value = event.target.value;
        setRemindTime(value);

        if (!value) {
            setErrorMessage('');
        } else if (!/^[1-8]$/.test(value)) {
            setErrorMessage('Value must be between 1 and 8');
        } else {
            setErrorMessage('');
        }

        setFormData((prevData) => ({
            ...prevData,
            hours: selectedCheckbox === 'hours' ? parseInt(value, 10) : 0,
            days: selectedCheckbox === 'days' ? parseInt(value, 10) : 0,
            months: selectedCheckbox === 'months' ? parseInt(value, 10) : 0
        }));
    };

    const handleDescriptionChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            description: event.target.value
        }));
    };

    const handleCancel = () => {
        setFormData(initialFormData);
        setSelectedCheckbox("");
        setRemindTime("");
        setErrorMessage("");
    };




    return (
        <div
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasReminder"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
            .offcanvas.custom-offcanvas {
                width: 450px !important; /* Adjust this as needed */
            }

            @media (max-width: 576px) {
                .offcanvas.custom-offcanvas {
                width: 70% !important; /* Adjust the percentage as needed */
                }
            }
            `}
            </style>
            <div className="offcanvas-header">
                <h4>Set Reminder</h4>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ms-2">
                <form>
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <h6>{taskOBJ.staff_names}</h6>
                        </div>
                        {/* <div className="col-md-6 col-lg-6 mb-4">
                            <h6>{cusOBJ.city}</h6>
                        </div> */}
                        <div className="mb-3 col-12">
                            <label className="form-label">Staff</label>
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose staff"
                                    value={selectedStaffId || null}
                                    onChange={handelStaffChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={staffOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {/* <p className="me-3 mb-0">Time Period:<span className="fw-bold text-danger ms-1">*</span></p> */}
                            <div className="form-group d-flex align-items-center me-3">
                                <label className="form-label me-1" htmlFor="Hours">Hours</label>
                                <input
                                    type="checkbox"
                                    id="Hours"
                                    checked={selectedCheckbox === "hours"}
                                    onChange={() => handleCheckboxChange("hours")}
                                />
                            </div>
                            <div className="form-group d-flex align-items-center me-3">
                                <label className="form-label me-1" htmlFor="Days">Days</label>
                                <input
                                    type="checkbox"
                                    id="Days"
                                    checked={selectedCheckbox === "days"}
                                    onChange={() => handleCheckboxChange("days")}
                                />
                            </div>
                            <div className="form-group d-flex align-items-center">
                                <label className="form-label me-1" htmlFor="Months">Months</label>
                                <input
                                    type="checkbox"
                                    id="Months"
                                    checked={selectedCheckbox === "months"}
                                    onChange={() => handleCheckboxChange("months")}
                                />
                            </div>
                            <div className="form-group d-flex align-items-center ms-3">
                                <select
                                    className={`form-select ${errorMessage ? 'is-invalid' : ''}`}
                                    value={remindTime}
                                    onChange={handleChange1}
                                    required
                                >
                                    <option value="">Select Time</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                </select>
                                {errorMessage && <div className="invalid-feedback text-danger">{errorMessage}</div>}
                            </div>
                        </div>
                        <div className="form-group col-md-12 my-4">
                            <label className="form-label">Notes</label>
                            <textarea
                                className="form-control"
                                placeholder="Enter notes ..."
                                value={formData.description}
                                onChange={handleDescriptionChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-2 m-auto">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
}

SetReminder.propTypes = {
    taskOBJ: PropTypes.array.isRequired,
};
