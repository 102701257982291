import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import AddStatus from "./AddStatus";
import AddSource from "./AddSource";
import "../../Config/swal.css";
import { useLabelContext } from "../../../Context";

export default function EditLead() {
  // const { id } = useParams();
  const { ID } = useLabelContext();
  const id = ID;
  const navigate = useNavigate();

  const [leadsData, setLeadsData] = useState({});
  const [leadsStatusData, setLeadsStatusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [selectedSourceId, setSelectedSourceId] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [propertyData, setpropertyData] = useState([]);
  const [selectedproperty, setselectedproperty] = useState([]);
  const [formValues, setFormValues] = useState({
    Name: "",
    Title: "",
    Company: "",
    Description: "",
    Country: "",
    City: "",
    Zip: "",
    lastcontact: "",
    State: "",
    Address: "",
    Source: "",
    Status: "",
    Email: "",
    Website: "",
    PhoneNumber: "",
  });

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}leads/getpropertytype`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setpropertyData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setpropertyData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const propertyoptions = propertyData && propertyData.map((item) => ({
    value: item.id,
    label: item.name
  }))

  const sourceOptions =
    leadSourceData &&
    leadSourceData.map((item) => ({
      value: item.SourceId,
      label: item.SourceName,
    }));

  const statusOptions =
    leadsStatusData &&
    leadsStatusData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const handleSourceSelectChange = (selectedOption) => {
    setSelectedSourceId(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      Source: selectedOption,
    }));
  };

  const handleStatusSelectChange = (selectedOption) => {
    setSelectedStatusId(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      Status: selectedOption,
    }));
  };

  const handlePropertySelectChange = (selectedOption) => {
    setselectedproperty(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      Company: selectedOption,
    }));
  };

  useEffect(() => {
    if (id) {
      sessionStorage.setItem("purchaseId", JSON.stringify(id));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}leads/GetLeadDetails?LeadId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        console.log(data.ResultData[0], "ssssssssssssssssssssssssss");

        if (data.Status) {
          setLeadsData(data.ResultData[0]);
          setFormValues({
            Name: data.ResultData[0].name || "",
            Title: data.ResultData[0].title || "",
            Company: data.ResultData[0].company || "",
            Description: data.ResultData[0].description || "",
            Country: data.ResultData[0].country || "",
            City: data.ResultData[0].city || "",
            Zip: data.ResultData[0].zip || "",
            DefaultLanguage: data.ResultData[0].default_language || "",
            State: data.ResultData[0].state || "",
            Address: data.ResultData[0].address || "",
            Source: data.ResultData[0].source || "",
            lastcontact: formatDate(data.ResultData[0].lastcontact) || "",
            AddedFrom: data.ResultData[0].addedfrom || "",
            Status: data.ResultData[0].status || "",
            Email: data.ResultData[0].email || "",
            Website: data.ResultData[0].website || "",
            PhoneNumber: data.ResultData[0].phonenumber || "",
          });

          setSelectedSourceId(data.ResultData[0].source || "");
          setSelectedStatusId(data.ResultData[0].status || "");
          setselectedproperty(data.ResultData[0].pname || "");
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}leads/GetLeadStatus`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setLeadsStatusData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}leads/GetLeadSources`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setLeadSourceData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setLeadSourceData([]); // Set an empty array to avoid errors
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const formattedDate = formValues.lastcontact
        ? new Date(formValues.lastcontact).toISOString().replace("T", " ").slice(0, 19)
        : null;

      console.log(formattedDate);

      const formattedValues = {
        ...formValues,
        lastcontact: formattedDate,
        Status: selectedStatusId,
      };

      console.log("Data sending to API:", formattedValues);

      const response = await fetch(`${API_BASE_CRM_URL}leads/UpdateLead`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Id: id,
          ...formattedValues,
        }),
      });

      const result = await response.json();

      if (response.ok && result.Status) {

        Swal.fire({
          title: "Lead Updated Successfully",
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/leads");
          }
        });
      } else {

        Swal.fire({
          title: "Error",
          text: result?.message || "Failed to update the lead.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Error",
        text: "An unexpected error occurred while updating the lead.",
        icon: "error",
        customClass: {
          title: "swal-title",
          content: "swal-text",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  console.log(selectedproperty);

  return (
    <Base>
      <div className="page-heade mb-3">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="page-title">
              <h4>Edit Lead</h4>
              <p className="fw-semibold">
                Modify the details of the lead below
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
            <Link to="/leads">
              <button
                className="btn btn-primary"
                style={{ borderRadius: "10px" }}
              >
                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-xxl-12 col-xl-12">
        <div className="card">
          <div
            className="card-body"
            style={{ maxHeight: "100vh", overflowY: "auto" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="row m-3">
                <h5 className="mb-2">Leads Infromation:</h5>
                <div className="col-md-4 col-lg-4 mb-2 d-flex flex-column">
                  <label className="form-label">property type</label>

                  <Select
                    showSearch
                    placeholder="Choose status"
                    value={selectedproperty || null}
                    onChange={handlePropertySelectChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={propertyoptions}
                    style={{ flexGrow: 1, height: "2.4rem" }}
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2 d-flex flex-column">
                  <label className="form-label">
                    Source<span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <Select
                      showSearch
                      placeholder="Choose source"
                      value={selectedSourceId || null}
                      onChange={handleSourceSelectChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={sourceOptions}
                      style={{ flexGrow: 1, height: "2.4rem" }}
                    />
                    <button
                      type="button"
                      className="btn btn-dark text-light ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add-source"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 mb-2 d-flex flex-column">
                  <label className="form-label">
                    Status<span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <Select
                      showSearch
                      placeholder="Choose status"
                      value={selectedStatusId || null}
                      onChange={handleStatusSelectChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={statusOptions}
                      style={{ flexGrow: 1, height: "2.4rem" }}
                    />
                    <button
                      type="button"
                      className="btn btn-dark text-light ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add-status"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Address</label>
                  <input
                    className="form-control"
                    type="text"
                    name="Address"
                    value={formValues.Address}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">City</label>
                  <input
                    className="form-control"
                    type="text"
                    name="City"
                    value={formValues.City}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Budget</label>
                  <input
                    className="form-control"
                    type="text"
                    name="Zip"
                    value={formValues.Zip}
                    onChange={handleChange}
                  />
                </div>

                <h5 className="mb-2 mt-3">Lead Person Details:</h5>
                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="Name"
                    value={formValues.Name}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    name="Title"
                    value={formValues.Title}
                    onChange={handleChange}
                  />
                </div> */}

                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Phone Number</label>
                  <input
                    className="form-control"
                    type="text"
                    name="PhoneNumber"
                    value={formValues.PhoneNumber}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    value={formValues.Email}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-lg-4 mb-2">
                  <label className="form-label">Last contact</label>
                  <input
                    className="form-control"
                    type="date"
                    name="LastContact"
                    value={formValues.LastContact}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row m-3 m-auto text-center">
                <button
                  type="submit"
                  className="btn btn-primary col-2 m-auto text-center"
                >
                  {loading ? "Submitting..." : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <AddStatus />
      <AddSource />
    </Base>
  );
}
