import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Base from "../../Config/Base";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import { useLabelContext } from "../../../Context";

import FollowUp from "./FollowUp";
import SetReminder from "./SetReminder copy";
import { formatDistanceToNow } from "date-fns";
const Tasks = () => {
  const [loading, setLoading] = useState(false);
  const [tasksData, setTasksData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [remidnerTaskOBJ, setRemidnerTaskOBJ] = useState([]);
  const [followupTaskOBJ, setFollowupTaskOBJ] = useState([]);
  const { setID } = useLabelContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}tasks/GetTasksnew`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setTasksData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setTasksData([]);
        }
      } catch (error) {
        console.error("Error fetching datasssss:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getStatusColor = (status) => {
    switch (parseInt(status, 10)) {
      case 1:
        return "red";
      case 2:
        return "blue";
      case 3:
        return "orange";
      case 4:
        return "gray";
      case 5:
        return "green";
      default:
        return "black"; // Fallback color
    }
  };



  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = tasksData.filter(
  //   (item) =>
  //     (item.name && item.name.toString().includes(searchTerm.toString())) ||
  //     (item.priority &&
  //       item.priority.toLowerCase().includes(searchTerm.toLowerCase()))
  // );

  const filteredItems = useMemo(() => {
    const results = tasksData.filter(
      (item) =>
        (item.name && item.name.toString().includes(searchTerm.toString())) ||
        (item.staff_names && item.staff_names.toString().includes(searchTerm.toString()))
      // (item.priority &&
      //   item.priority.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setCurrentPage(1);
    return results;
  }, [tasksData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  console.log(filteredItems);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleExcelDownload = () => {
    if (tasksData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = tasksData.map((item) => ({
      Name: item.name,

      "Start Date": formatDate(item.startdate),
      "Due Date": formatDate(item.duedate),
      "Assigned To": item.staff_names,
      Tags: item.tag_name,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "tasksData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "tasksData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Tasks",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = ["Name", "Start Date", "Due Date", "Assigned to", "Tags"];
    tableData.push(headers);

    // Add rows
    tasksData.forEach((item) => {
      const row = [];
      row.push(
        item.name,
        formatDate(item.startdate),
        formatDate(item.duedate),
        item.staff_names,
        item.tag_name
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("tasksData.pdf");
  };

  const showReminderModal = (obj) => {
    setRemidnerTaskOBJ(obj);
  };

  const showFollowupModal = (obj) => {
    setFollowupTaskOBJ(obj);
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.id);
  };

  const handledelete = async (item) => {
    console.log(item);
    try {
      const response = await fetch(`${API_BASE_CRM_URL}tasks/DeleteTask`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Id: item.id }),
      });
      console.log(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Task Deleted Successfully`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            // window.location.reload();
            // navigate("/delivery-challan");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleStatusChange = (id, newStatus) => {
    fetch(`${API_BASE_CRM_URL}tasks/UpdateTaskStatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Id: id, status: newStatus }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status) {
          alert("Status updated successfully");
          window.location.reload();

        } else {
          alert(data.error || "Failed to update status");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        alert("An error occurred while updating the status.");
      });
  };

  const handlePriorityChange = (id, newStatus) => {
    fetch(`${API_BASE_CRM_URL}tasks/UpdateTaskPriority`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Id: id, status: newStatus }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status) {
          alert("priority updated successfully");
          window.location.reload();

        } else {
          alert(data.error || "Failed to update status");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        alert("An error occurred while updating the status.");
      });
  };

  const formatDates = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Tasks</h4>
            <h6>Manage your Task Summary</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-task">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body" style={{ minHeight: "100px" }}>
          <div className="table-responsive">
            <table className="table dataew mb-2 align-middle">
              <thead>
                <tr>
                  <th scope="col" className="no-sort text-center">#</th>
                  <th scope="col" className="no-sort text-start">Action</th>
                  <th scope="col" className="no-sort text-start">Task Type</th>
                  <th scope="col" className="text-center">Assigned to</th>
                  <th scope="col" className="text-center">Status</th>
                  <th scope="col" className="text-center">Start Date</th>
                  <th scope="col" className="text-center">Due Date</th>
                  <th scope="col" className="text-center">Priority</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="loading-container d-flex justify-content-center">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center">{indexOfFirstItem + index + 1}</td>
                      <td className="text-center">
                        <div className="dropdown">
                          <button
                            className="btn btn-sm btn-light"
                            type="button"
                            id={`dropdownMenuButton${index}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v"></i>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby={`dropdownMenuButton${index}`}
                          >
                            <li>
                              <Link
                                to={`/view-task`}
                                className="dropdown-item"
                                onClick={() => handleViewPurchaseOrders(item)}
                              >
                                <i className="fa-regular fa-eye me-2"></i> View
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/edit-task`}
                                onClick={() => handleViewPurchaseOrders(item)}
                              >
                                <i className="fa-regular fa-pen-to-square text-info me-2"></i> Edit
                              </Link>
                            </li>
                            <li>
                              <button
                                className={`dropdown-item ${item.status === 1 ? "" : "disabled"
                                  }`}
                                onClick={() => handledelete(item)}
                              >
                                <i className="fa-regular fa-trash-can text-danger me-2"></i> Delete
                              </button>
                            </li>
                            <li>
                              <button
                                className={`dropdown-item ${item.status === 1 ? "" : "disabled"
                                  }`}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasReminder"
                                aria-controls="offcanvasReminder"
                                onClick={() => showReminderModal(item)}
                              >
                                <i className="fa-solid fa-reply-all me-2"></i> Reminder
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td>
                        <Link
                          to={`/view-task`}
                          className="text-decoration-none"
                          onClick={() => handleViewPurchaseOrders(item)}
                        >
                          {item.name}
                        </Link>
                      </td>
                      <td>
                        {item.staff_names ? (
                          item.staff_names.split(", ").map((name, idx) => (
                            <span className="d-inline-block me-2" key={idx}>
                              <i className="fas fa-user me-1"></i> {name}
                            </span>
                          ))
                        ) : (
                          "N/A"
                        )}
                      </td>

                      <td className="text-center">
                        <select
                          className="form-select form-select-sm"
                          value={item.status}
                          onChange={(e) => handleStatusChange(item.id, e.target.value)}
                        >
                          <option value={1}>Not Started</option>
                          <option value={2}>In Progress</option>
                          <option value={3}>Awaiting Feedback</option>
                          <option value={4}>Testing</option>
                          <option value={5}>Completed</option>
                        </select>
                      </td>
                      <td className="text-center">{formatDates(item.startdate)}</td>
                      <td className="text-center">{formatDates(item.duedate)}</td>
                      <td className="text-center">
                        <select
                          className="form-select form-select-sm"
                          value={item.priority}
                          onChange={(e) => handlePriorityChange(item.id, e.target.value)}
                        >
                          <option value={1}>Urgent</option>
                          <option value={2}>High</option>
                          <option value={3}>Medium</option>
                          <option value={4}>Low</option>
                          <option value={5}>Complete</option>
                        </select>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={tasksData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>





      <SetReminder taskOBJ={remidnerTaskOBJ} ></SetReminder>
      {/* <FollowUp taskBJ={followupTaskOBJ} /> */}
    </Base>
  );
};

export default Tasks;
