import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_CRM_URL } from "../../Config/Config";
import CRMLOGO from '../../../Assets/logo1.png';
import PCODE from '../../../Assets/crmlogo.jpg';
import LOGIN from '../../../Assets/login.jpeg'
import "./login.css";

export default function Login() {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Check if user session exists
        const userDetails = sessionStorage.getItem('userDetails');
        if (userDetails) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                Email: email,
                Password: password
            };

            console.log(payload, 'lkllllllllllllll')
            const response = await fetch(`${API_BASE_CRM_URL}Login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();
            console.log(data, 'ddddddddddd')

            if (data.Status) {
                sessionStorage.setItem('userDetails', JSON.stringify(data.ResultData));
                console.log("Login successful", data.ResultData);
                navigate('/dashboard');
            } else {
                setError(data.error);
            }
        } catch (err) {
            console.error("Error during login:", err);
            setError("An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="main-wrapper">
            <div className="account-content d-flex">

                <div className="image-wrapper col-md-6 d-flex align-items-center justify-content-center hide-on-mobile">
                    {(!isMobile) && (
                        <img
                            src={LOGIN}
                            alt="Background"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '50%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: -1
                            }}
                        />
                    )}
                </div>

                <div className="login-wrapper col-md-6">
                    <div className="login-content">
                        <form onSubmit={handleSubmit}>
                            <div className="login-userset">
                                {/* <div className="login-logo logo-normal" style={{ width: '300px' }}>
                                    <img src={PCODE} alt="img" />
                                </div> */}
                                <div className="login-logo logo-normal">
                                    <img src={CRMLOGO} alt="img" />
                                </div>
                                <div className="login-userheading">
                                    <h3>Sign In</h3>
                                    <h4>Access the Dreamspos panel using your email and passcode.</h4>
                                </div>
                                <div className="form-login mb-3">
                                    <label className="form-label">Email Address</label>
                                    <div className="form-addons">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter email address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <img src="assets/img/icons/mail.svg" alt="img" />
                                    </div>
                                </div>
                                <div className="form-login mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="pass-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="pass-input form-control"
                                            placeholder="Enter password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                            required
                                        />
                                        <span
                                            className="ha"
                                            onClick={togglePasswordVisibility}
                                        ></span>
                                    </div>
                                </div>
                                <div className="form-login authentication-check">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <div className="custom-control custom-checkbox">
                                                <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                                                    <input
                                                        type="checkbox"
                                                        className="form-control"
                                                    />
                                                    <span className="checkmarks"></span>Remember me
                                                </label>
                                            </div>
                                            <div className="text-end">
                                                <a className="forgot-link">Forgot Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-login">
                                    <button type="submit" className="btn btn-login" disabled={loading}>
                                        {loading ? "Signing in..." : "Sign In"}
                                    </button>
                                </div>
                                <div className="signinform">
                                    <h4>
                                        New on our platform?<a className="hover-a"> Create an account</a>
                                    </h4>
                                </div>
                                <div className="  login-footer text-center mt-4">
                                    <span>
                                        <Link to="https://perennialcode.in/" className="footer-link">
                                            <img src={PCODE} alt="Perennial Code Logo" className="footer-logo" />
                                            <span className="footer-text">@perennialcode</span>
                                        </Link>
                                    </span>
                                </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>


    );
}
