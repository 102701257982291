import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Base from "../../Config/Base";
import "../../../Assets/popup.css";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../Config/search.css";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";
import "../../Config/loader.css";
import Swal from "sweetalert2";
import AddContact from "./AddContact";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useLabelContext } from "../../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBuilding, faCity, faHome, faHouseUser, faIndustry, faMap, faTractor } from "@fortawesome/free-solid-svg-icons";
import res from "../../../Assets/residential.png";
import ind from "../../../Assets/industrial.png";
import house from "../../../Assets/house.png";
import building from "../../../Assets/building.png";
import agri from "../../../Assets/Agri.jpg";
import com from "../../../Assets/office-building.png";
import plot from "../../../Assets/plot.jpg";

export default function Customers() {
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remidnerCusOBJ, setRemidnerCusOBJ] = useState([]);
  const [followupCusOBJ, setFollowupCusOBJ] = useState([]);
  const [addContactOBJ, setAddContactOBJ] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { setID } = useLabelContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}customers/GetCustomers`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setCustomersData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomersData([]); // Set an empty array to avoid errors
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + 'Cr';
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'L';
    if (num >= 1_000) return (num / 1_000).toFixed(1) + 'K';
    return num;
  };

  const showReminderModal = (obj) => {
    setRemidnerCusOBJ(obj);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = customersData.filter(
  //   (item) =>
  //     (item.vat && item.vat.toString().includes(searchTerm.toString())) ||
  //     (item.company &&
  //       item.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.city &&
  //       item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.phonenumber &&
  //       item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()))
  // );


  const filteredItems = useMemo(() => {
    const results = customersData.filter(
      (item) =>
        (item.vat && item.vat.toString().includes(searchTerm.toString())) ||
        (item.company &&
          item.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.city &&
          item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.phonenumber &&
          item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setCurrentPage(1);

    return results;
  }, [customersData, searchTerm]);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const showFollowupModal = (obj) => {
    setFollowupCusOBJ(obj);
  };

  const showAddContactModal = (obj) => {
    setAddContactOBJ(obj);
  };

  const handleExcelDownload = () => {
    if (customersData.length === 0) {
      return;
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = customersData.map((item) => ({
      Company: item.company,
      "Date Created": formatDate(item.datecreated),
      City: item.city,
      State: item.state,
      Zipcode: item.zip,
      Website: item.website,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);


    XLSX.utils.book_append_sheet(wb, ws, "CustoemrsData");

    XLSX.writeFile(wb, "CustoemrsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();


    doc.setProperties({
      title: "Customers Data",
      author: "Your Name",
      creator: "Your Application",
    });


    const tableData = [];


    const headers = [
      "Property type",
      "Name",
      "Mobile",
      "City",
      "State",
      "Budget",

    ];
    tableData.push(headers);


    customersData.forEach((item) => {
      const row = [];
      row.push(
        item.company,
        item.name,
        item.phonenumber,
        item.city,
        item.state,
        item.zip,
        item.website
      );
      tableData.push(row);
    });


    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("CustomersData.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const toggleActiveStatus = async (userId, currentStatus) => {
    try {
      const response = await fetch(`${API_BASE_CRM_URL}customers/ActiveCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ UserId: userId, active: currentStatus ? 0 : 1 }),
      });

      const result = await response.json();

      if (result.Status) {

        setCustomersData((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.userid === userId
              ? { ...customer, active: currentStatus ? 0 : 1 }
              : customer
          )
        );
        Swal.fire("Success", "Customer status updated successfully", "success");
      } else {
        Swal.fire("Error", "Failed to update customer status", "error");
      }
    } catch (error) {
      console.error("Error updating customer status:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating customer status",
        "error"
      );
    }
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.userid);
  };

  const totalLeads = customersData.length;
  const activeLeads = customersData.filter(lead => lead.active === 1).length;
  const inactiveLeads = totalLeads - activeLeads;

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Customers</h4>
            <h6>Manage your Customers</h6>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
          <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "50px", padding: "5px 10px" }}>
            <div className="card-body d-flex justify-content-start align-items-center p-0">
              <i className="fa-solid fa-users fa-lg text-primary me-2"></i>
              <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", marginTop: '5px' }}>Total</h5>
              <p className="card-text fs-6 mb-0" style={{ fontSize: "14px" }}>{totalLeads}</p>
            </div>
          </div>

          <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "50px", padding: "5px 10px" }}>
            <div className="card-body d-flex justify-content-start align-items-center p-0">
              <i className="fa-solid fa-user-check fa-lg text-success me-2"></i>
              <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", marginTop: '5px' }}>Active</h5>
              <p className="card-text fs-6 mb-0" style={{ fontSize: "14px" }}>{activeLeads}</p>
            </div>
          </div>
          <div className="card text-center flex-fill mx-2 mb-3" style={{ height: "50px", padding: "5px 10px" }}>
            <div className="card-body d-flex justify-content-start align-items-center p-0">
              <i className="fa-solid fa-user-slash fa-lg text-danger me-2"></i>
              <h5 className="card-title mb-0" style={{ fontSize: "12px", marginRight: "5px", marginTop: '5px' }}>Inactive</h5>
              <p className="card-text fs-6 mb-0" style={{ fontSize: "14px" }}>{inactiveLeads}</p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>

            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-customer">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card flex-grow-1">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table datnew w-100">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th className="no-sot">Action</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Property Type</th>
                  <th>Property</th>
                  <th>City</th>
                  <th>Budget</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={9} className="text-center">
                      <div className="containers m-auto">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  currentItems && currentItems.map((item, index) => (
                    <tr
                      key={index}
                      className={`hover-shadow ${index % 2 === 0 ? "bg-light" : "bg-white"}`}
                    >
                      <td className="text-muted">{index + 1}</td>
                      <td>
                        <div className="dropdown">
                          <a
                            className="action-set text-dark"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              to={`/view-customer`}
                              className="dropdown-item text-primary"
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className={`dropdown-item ${item.active ? "" : "disabled text-muted"}`}
                              to={`/edit-customer`}
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit
                            </Link>
                            <a
                              className={`dropdown-item ${item.active ? "" : "disabled text-muted"}`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasContact"
                              aria-controls="offcanvasContact"
                              onClick={() => showAddContactModal(item)}
                            >
                              <i className="fa-regular fa-address-book me-2 text-success"></i>
                              Add Contact
                            </a>
                            <a
                              className={`dropdown-item ${item.active ? "" : "disabled text-muted"}`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasReminder"
                              aria-controls="offcanvasReminder"
                              onClick={() => showReminderModal(item)}
                            >
                              <i className="fa-solid fa-business-time me-2 text-info"></i>
                              Set Reminder
                            </a>
                            <a
                              className={`dropdown-item ${item.active ? "" : "disabled text-muted"}`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasFollowup"
                              aria-controls="offcanvasFollowup"
                              onClick={() => showFollowupModal(item)}
                            >
                              <i className="fa-solid fa-reply-all me-2 text-primary"></i>
                              Follow Up
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => toggleActiveStatus(item.userid, item.active)}
                            >
                              <i className={`fa-solid fa-toggle-${item.active ? "off text-danger" : "on text-success"} me-2`}></i>
                              {item.active ? "Not Completed" : "Completed"}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link to={`/view-customer`} onClick={() => handleViewPurchaseOrders(item)} className="fw-bold ">
                          {item.vat}
                        </Link>
                      </td>
                      <td>{item.phonenumber}</td>
                      <td className="text-info d-flex justify-content-center align-items-center">
                        <img
                          src={
                            item.pname === "Residential"
                              ? res
                              : item.pname === "Commercial"
                                ? com
                                : item.pname === "Industrial"
                                  ? ind
                                  : item.pname === "Agricultural"
                                    ? agri
                                    : item.pname === "Independent House"
                                      ? house
                                      : item.pname === "Plot"
                                        ? plot
                                        : item.pname === "Apartment"
                                          ? building
                                          : null
                          }
                          alt={item.pname}
                          title={item.pname}
                          style={{ width: "26px", height: "26px" }}
                          className={`me-1 ${item.pname === "Residential"
                            ? "text-success"
                            : item.pname === "Commercial"
                              ? "text-primary"
                              : item.pname === "Industrial"
                                ? "text-warning"
                                : item.pname === "Agricultural"
                                  ? "text-success"
                                  : item.pname === "Independent House"
                                    ? "text-info"
                                    : item.pname === "Apartment"
                                      ? "text-danger"
                                      : ""
                            }`}
                        />
                      </td>

                      <td>{item.projectname}</td>
                      <td className="text-info">{item.city}</td>
                      <td className="text-success fw-bold">{formatNumber(item.zip)}</td>
                      <td>
                        <i
                          className={`fa ${item.active === 1 ? "fa-toggle-on text-success" : "fa-toggle-off text-danger"}`}
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={customersData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>

      <SetReminder cusOBJ={remidnerCusOBJ} />
      <FollowUp cusOBJ={followupCusOBJ} />
      <AddContact cusOBJ={addContactOBJ} />
    </Base>
  );
}
