import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import Pagination from "../../Config/Pagination";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import "../../Config/search.css";
import "../../Config/loader.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Config/loading.css";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { useLabelContext } from "../../../Context";
import { formatDistanceToNow } from "date-fns";

export default function Invoice() {
  const navigate = useNavigate();
  const { setID } = useLabelContext();

  const [productsData, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [productViewOBJ, setProductViewObj] = useState([]);
  const [productEditOBJ, setProductEditObj] = useState([]);

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetInvoices`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)

        if (data.Status) {
          setProducts(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const showProductViewModal = (obj) => {
    setProductViewObj(obj);
  };

  const showProductEditModal = (obj) => {
    setProductEditObj(obj);
  };

  const handleViewInvoice = (item) => {
    console.log(item);
    setID(item.id);
  }

  const handleEditInvoice = (item) => {
    console.log(item);
    setID(item.id);
  };

  const toggleActiveStatus = async (Id, currentStatus) => {
    try {
      const response = await fetch(`${API_BASE_CRM_URL}products/ActiveProduct`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Id: Id, active: currentStatus ? 0 : 1 }),
      });

      const result = await response.json();

      if (result.Status) {
        // Update the local state to reflect the change
        setProducts((prevProducts) =>
          prevProducts.map((prod) =>
            prod.id === Id ? { ...prod, status: currentStatus ? 0 : 1 } : prod
          )
        );
        Swal.fire("Success", "Product status updated successfully", "success");
        window.location.reload();
      } else {
        Swal.fire("Error", "Failed to update product status", "error");
      }
    } catch (error) {
      console.error("Error updating product status:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating product status",
        "error"
      );
    }
  };

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const itemsPerPage = 10;

  // const filteredItems = productsData.filter((item) =>
  //   item.number.toString().includes(searchTerm.toLowerCase())
  // );

  const filteredItems = useMemo(() => {
    const results = productsData.filter((item) =>
      item.number.toString().includes(searchTerm.toLowerCase()) ||
      (item.deleted_customer_name &&
        item.deleted_customer_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setCurrentPage(1);
    return results;
  }, [productsData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExcelDownload = () => {
    if (productsData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = productsData.map((item) => ({
      Number: `INV-${item.number}`,
      Amount: item.totalamount,
      "Total Tax": item.totaltax,
      Date: item.date ? formatDate(item.date) : "",
      Customer: item.cus_name,
      Project: item.proj_name,
      "Due Date": item.duedate ? formatDate(item.duedate) : "",
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "productsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "invoice.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Invoice",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Number",
      "Amount",
      "Total Tax",
      "Date",
      "Customer",
      "Project",
      "Due Date",
    ];
    tableData.push(headers);

    // Add rows
    productsData.forEach((item) => {
      const row = [];
      row.push(
        `INV-${item.number}`,
        item.totalamount,
        item.totaltax,
        item.date ? formatDate(item.date) : "",
        item.cus_name,
        item.proj_name,
        item.duedate ? formatDate(item.duedate) : ""
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("invoice.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + 'Cr';
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + 'L';
    if (num >= 1_000) return (num / 1_000).toFixed(1) + 'K';
    return num;
  };

  const formatDates = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Transactions</h4>
            <h6>Manage your Transactions</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-invoice">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Action</th>
                  <th>Agent</th>
                  <th>Customer</th>
                  <th>Project</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="spinner-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              className={`dropdown-item ${item.status ? "" : "disabled"}`}
                              to="/view-invoice"
                              onClick={() => handleViewInvoice(item)}
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className={`dropdown-item ${item.status ? "" : "disabled"}`}
                              to="/edit-invoice"
                              onClick={() => handleEditInvoice(item)}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit
                            </Link>
                            <a
                              className="dropdown-item"
                              onClick={() => toggleActiveStatus(item.id, item.status)}
                            >
                              <i
                                className={`fa-solid fa-toggle-${item.status ? "off text-danger" : "on text-success"
                                  } me-2`}
                              ></i>
                              {item.status ? "Deactivate" : "Activate"}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="fw-bold">{item.deleted_customer_name || "N/A"}</td>
                      <td>{item.vat || "N/A"}</td>
                      <td>{item.proj_name || "N/A"}</td>
                      <td className="text-success fw-bold">{formatNumber(item.total)}</td>
                      <td className="text-info">{formatDates(item.date)}</td>
                      {/* <td>
                        {item.status === 1 ? (
                          <span className="badge bg-success">Active</span>
                        ) : (
                          <span className="badge bg-danger">Inactive</span>
                        )}
                      </td> */}
                      <td>
                        <i
                          className={`fa ${item.status === 1 ? "fa-toggle-on text-success" : "fa-toggle-off text-danger"}`}
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={productsData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>

    </Base>
  );
}
