
import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from '../../Config/Pagination';
import '../../../Assets/popup.css';
import ViewFollowUp from "./ViewFollwup";
import EditFollowUp from './EditFollwUp';
import PDFIMG from '../../Config/pdf.svg';
import EXCELIMG from '../../Config/excel.svg';
import AddFollowUp from "./AddFollowup";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../Config/loading.css';


export default function FollowUps() {

    const [loading, setLoading] = useState(false);
    const [followupsData, setFollowupsData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [followupEditOBJ, setFollowupEditOBJ] = useState([]);
    const [followupViewOBJ, setFollowupViewOBJ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}followups/GetFollowups`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setFollowupsData(data.ResultData);
                    console.log(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setFollowupsData([]); // Set an empty array to avoid errors
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const showFollowupModal = (obj) => {
        setFollowupEditOBJ(obj);
    };

    const showFollowupViewModal = (obj) => {
        setFollowupViewOBJ(obj);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // const filteredItems = followupsData.filter(item => 
    //     (item.Title && item.Title.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (item.RelatedName && item.RelatedName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (item.relType && item.relType.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (item.FollowupType && item.FollowupType.toLowerCase().includes(searchTerm.toLowerCase()))
    // );    


    const filteredItems = useMemo(() => {
        const results = followupsData.filter(
            (item) =>
                (item.Title && item.Title.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.RelatedName && item.RelatedName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.relType && item.relType.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.FollowupType && item.FollowupType.toLowerCase().includes(searchTerm.toLowerCase()))
        );


        setCurrentPage(1);

        return results;
    }, [followupsData, searchTerm]);

    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const deleteFollowup = (id) => {
        const url = `${API_BASE_CRM_URL}followups/ActiveFollowup`;
        const payload = { ID: id };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.Status) {
                    Swal.fire("Status Changed!", "Followup status has been changed.", "success");
                    setFollowupsData((prevFollowups) =>
                        prevFollowups.filter((followup) => followup.ID !== id)
                    );

                } else {
                    Swal.fire("Error!", "There was an error deleting the followup.", "error");
                }
            })
            .catch((error) => {
                console.error("Error deleting followup:", error);
                Swal.fire("Error!", "There was an error deleting the followup.", "error");
            });
    };

    const handleExcelDownload = () => {
        if (followupsData.length === 0) {
            return;
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = followupsData.map((item) => ({
            'Title': item.Title,
            'Type': item.relType,
            'Followup Type': item.FollowupType,
            'Related Name': item.RelatedName,
            'Phone Number': item.phonenumber,
            'Followup Date': formatDate(item.Date),
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'FollowupsData');

        // Save the workbook to a file
        XLSX.writeFile(wb, 'FollowupsData.xlsx');
    };

    const handlePDFDownload = () => {
        if (followupsData.length === 0) {
            return;
        }
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: 'Followups Data',
            author: 'Your Name',
            creator: 'Your Application'
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = ['Title', 'Type', 'Followup Type', 'Followup Date', 'Related Name', 'Source'];
        tableData.push(headers);

        // Add rows
        followupsData.forEach(item => {
            const row = [];
            row.push(item.Title, item.relType, item.FollowupType, formatDate(item.Date), item.RelatedName, item.SourceName);
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });

        // Save the PDF
        doc.save('FollowupsData.pdf');
    };

    const handleReload = () => {
        window.location.reload();
    }


    return (
        <Base>

            <div className="page-header">
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="page-title">
                        <h4>Customers</h4>
                        <h6>Manage your Customers</h6>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-3">
                    <div className="flex-fill d-md-flex justify-content-between align-items-center">
                        <div className="input-group mb-2 mb-md-0">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search..."
                                className="form-control"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Pdf"
                                onClick={handlePDFDownload}
                            >
                                <img src="assets/img/icons/pdf.svg" alt="PDF" />
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Excel"
                                onClick={handleExcelDownload}
                            >
                                <img src="assets/img/icons/excel.svg" alt="Excel" />
                            </a>
                        </li>
                        {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Refresh"
                                onClick={handleReload}
                            >
                                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Collapse"
                                id="collapse-header"
                            >
                                <i className="fa-solid fa-chevron-up fs-6"></i>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    {/* <div className="table-top">
                        <div className="search-set">
                            <div className="search-input">
                                <input 
                                    type="text" 
                                    name="search" 
                                    placeholder="Search..." 
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="table-responsive ">
                        <table className="table datane">
                            <thead>
                                <tr>
                                    <th className="no-sort">
                                        <label className="checkboxs">
                                            <input type="checkbox" id="select-all" />
                                            <span className="checkmarks"></span>
                                        </label>
                                    </th>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Related to</th>
                                    <th>Followup By</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ?
                                    <td colSpan={12} className="etxt-center">
                                        <p className="text-center">
                                            <div class="containers m-auto">
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                            </div>
                                        </p>
                                    </td> :
                                    currentItems && currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks"></span>
                                                </label>
                                            </td>
                                            <td>{item.relType}</td>
                                            <td>{item.Title}</td>
                                            <td>{item.RelatedName}</td>
                                            <td>{item.FollowupType}</td>
                                            <td>{formatDate(item.Date)}</td>
                                            <td>
                                                {item.IsActive === 1 ? (
                                                    <span className="badge bg-outline-success">Active</span>
                                                ) : (
                                                    <span className="badge bg-outline-danger">Inactive</span>
                                                )}
                                            </td>
                                            <td className="action-table-data">
                                                <div className="edit-delete-action">
                                                    <a className="me-2 edit-icon  p-2" data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasView"
                                                        aria-controls="offcanvasView"
                                                        onClick={() => showFollowupViewModal(item)}
                                                    >
                                                        <i className="fa-regular fa-eye"></i>
                                                    </a>
                                                    <a
                                                        className="me-2 p-2"
                                                        data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasEdit"
                                                        aria-controls="offcanvasEdit"
                                                        onClick={() => showFollowupModal(item)}
                                                    >
                                                        <i className="fa-regular fa-pen-to-square text-info"></i>
                                                    </a>
                                                    <a className="confirm-text p-2" href="javascript:void(0);"
                                                        onClick={() => deleteFollowup(item.ID)}
                                                    >
                                                        <i className={`fa-solid fa-toggle-${item.IsActive ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={followupsData.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>

                    {/* Mobile View */}
                    {/* <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ? 
                            <td colSpan={12} className="etxt-center">
                                <p className="loader m-auto my-5"></p>
                            </td> :
                            currentItems && currentItems.map((item, index) => (
                                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                    <div className="employee-grid-profile">
                                        <div className="profile-head">
                                            <label className="checkboxs">
                                                <input type="checkbox"/>
                                                <span className="checkmarks"></span>
                                            </label>
                                            <div className="profile-head-action">
                                                {item.IsActive === 1 ? (
                                                    <span className="badge bg-outline-success text-center w-auto me-1">Active</span>
                                                ) : (
                                                    <span className="badge bg-outline-danger text-center w-auto me-1">Inactive</span>
                                                )}
                                                <div className="dropdown profile-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            className="feather feather-more-vertical feather-user">
                                                            <circle cx="12" cy="12" r="1"></circle>
                                                            <circle cx="12" cy="5" r="1"></circle>
                                                            <circle cx="12" cy="19" r="1"></circle>
                                                        </svg></a>
                                                    <ul className="dropdown-menu">
                                                        <li className=" mt-2 mb-2">
                                                            <a className="me-2 edit-icon  p-2" data-bs-toggle="modal" data-bs-target="#view-followup" 
                                                                    onClick={() => showFollowupViewModal(item)}
                                                                >
                                                                    <i className="fa-regular fa-eye me-2"></i>View
                                                            </a>
                                                        </li>
                                                        <li className="mb-2">
                                                            <a 
                                                                className="me-2 p-2"  
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#edit-followup" 
                                                                onClick={() => showFollowupModal(item)}
                                                            >
                                                                <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit
                                                            </a>
                                                        </li>
                                                        <li className="mb-2">
                                                            <a className="confirm-text p-2" href="javascript:void(0);"
                                                                onClick={() => deleteFollowup(item.ID)}
                                                            >
                                                                <i className={`fa-solid fa-toggle-${item.IsActive ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                                {item.IsActive === 1 ? 'Deactive' : 'Active'}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile-info">
                                            <h5>Title : {item.Title}</h5>
                                            <h4>{item.RelatedName}</h4>
                                            <span>{item.FollowupType}</span>
                                        </div>
                                        <ul className="department">
                                            <li>
                                                Followup
                                                <span>{formatDate(item.Date)}</span>
                                            </li>
                                            <li>
                                                Created
                                                <span>{formatDate(item.CreatedOn)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                         ))}
                    </div> */}
                </div>
            </div>

            <ViewFollowUp folupObj={followupViewOBJ} />
            <EditFollowUp folupObj={followupEditOBJ} />
            <AddFollowUp />
        </Base>
    )
}